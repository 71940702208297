import { getConfig } from "@portal-frontend-ssr/config";

type ImageCategory = "card" | "minimap" | "portrait" | "small" | "top_bar" | "weapon";

export const generateDeadlockHeroImage = ({
  category,
  heroId,
}: {
  category: ImageCategory;
  heroId: string | number;
}) => {
  const baseAssetsURL = getConfig().BASE_ASSET_URL;

  return `${baseAssetsURL}/images/deadlock/heroes/${heroId}/${category}`;
};

export const generateDeadlockAbilityImage = ({ abilityId }: { abilityId: string }) => {
  const baseAssetsURL = getConfig().BASE_ASSET_URL;

  return `${baseAssetsURL}/images/deadlock/abilities/${abilityId}`;
};

export const generateDeadlockItemsImage = ({ itemId }: { itemId: string }) => {
  const baseAssetsURL = getConfig().BASE_ASSET_URL;

  return `${baseAssetsURL}/images/deadlock/items/${itemId}`;
};
