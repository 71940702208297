import { useEffect, useState } from "react";
import classNames from "classnames";
import { AdContainer } from "./AdContainer";
import { useMediaQuery } from "usehooks-ts";

/**
  This component is used to display sticky side rail ads on the left and right side of the page slot.
*/
export const StickySideRailAds = ({ className }: { className?: string }) => {
  const [renderAds, setRenderAds] = useState(false);
  const isBigScreen = useMediaQuery("(min-width: 1800px)");

  useEffect(() => {
    setRenderAds(isBigScreen);
  }, [isBigScreen]);

  // If the screen size is less than 1800px, those ads should be hidden
  if (!renderAds) {
    return null;
  }

  return (
    <div className={classNames("sticky top-0 h-0 w-full max-xl:hidden", className)}>
      <div className="relative mx-auto h-0 w-full max-w-8xl">
        <AdContainer type="skyscraper_left" className="absolute left-0 top-0 translate-x-[-110%]" />
        <AdContainer type="skyscraper_right" className="absolute right-0 top-0 translate-x-[110%]" />
      </div>
    </div>
  );
};
